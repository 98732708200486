<app-overlay-loader *ngIf="loader"></app-overlay-loader>
<!-- Terms and Conditions Model -->
      <p-dialog header="Terms and Conditions" [(visible)]="termsModel" [modal]="true" [closable]="false" [style]="{width: '70vw'}" [baseZIndex]="10000"
      [draggable]="false" [resizable]="false">
        <div class="p-m-0 p"> <div class="mb-4"> Please Read and Agree to our Terms & Conditions </div> 
            <div class="arabic_text">  
                <div>أولا: الاشتراك في الكورسات يتيح لك حريه الوصول الي كل محتوي العضويه علي الموقع لمده سنتين ونصف بالنسبه لكورسات الاونلاين والاوفلاين و ٤ سنوات بالنسبه لكورس الكومبو</div>
                <br><br> 
                <div class="second_line">ثانيا: في حاله الاحتياج الي تجديد العضويه يمكنك تجديد العضويه من خلال الضغط علي زر تجديد العضويه في صفحه الاكونت ويكون تجديد سنوي بمبلغ بسيط يمثل ثانيا: في حاله الاحتياج الي تجديد عضويه الكورسات يمكنك تجديد العضويه من خلال الضغط علي زر تجديد العضويه في صفحه الاكونت ويكون تجديد سنوي بمبلغ بسيط يمثل ٢٥٪؜ من سعر الكورس لمده عام .. وفي حاله عدم التجديد خلال شهرين من انتهاء العضويه يتم حذف الاكونت بكامل محتواه وعند الرغبه في الاشتراك مجددا يتم دفع كامل رسوم الكورس</div>
                <br><br>
                <div class="second_line mb-3">ثالثا: الاشتراك في بنوك الاسئله يتيح لك بنك الاسئله لمده سنه فقط فيما عدا اشتراك الكومبو يكون لمده سنتين وعند التجديد يتم دفع رسوم الاشتراك مجددا بالكامل .. وفي حاله عدم التجديد خلال شهرين من انتهاء العضويه يتم حذف الاكونت بكامل محتواه</div>
                <br><br>
                <div class="mt-2 mb-4">رابعا: نمتلك الحق للوصول لكل انشطه اي عضو علي الموقع من خلال نظام الامان بالموقع وهذا يشمل الدوله - المدينه - نوع الجهاز- نوع المتصفح - الفيديو الذي يتم مشاهدته - مشاهد المحاضره كامله او جزا منها - عدد مرات الدخول علي الموقع – سكورات حل الاسئله</div>
                <br><br>
                <div class="mb-4">خامسا: اي محاوله لعمل داونلود او تصوير الفيديوهات يعد مخالفا لسياسه الموقع ويتم غلق الحساب نهائيا علي الموقع</div>
                <br><br>
                <div>سادسا: الاكونت يكون شخصي لصاحب الاكونت فقط وغير متاح مشاركته او بيعه لاي شخص كان فرجاء الاحتفاظ ببيانات الاكونت لان مخالفه ذلك يعد مخالف لسياسات الموقع ويتم غلق الحساب نهائيا علي الموقع</div> 
                <br><br>
                <div class="mt-2">سابعا: الرجاء العلم غير متاح تغيير الاميل المسجل به، فالرجاء الاحتفاظ بالاميل المسجل به لحين انتهاء اشتراكك</div> 
                <br><br>
            </div> 
            <br> Refund policy: NO REFUND. <br>
            <br> Exchange policy: You have the right to change your course from offline to online or vice versa but only within the 1st week of your subscription, after that you can't.
        </div>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="gotoHomeScreen()" label="Accept" class="p-button-text"></p-button>
            <!-- <p-button icon="pi pi-times" (click)="displayModal1=false" label="No"></p-button> -->
        </ng-template>
      </p-dialog>
<div class="login_wrapper">
    <div class="left_content">
        <div class="heading">
            <span class="title">ELHUSSEINY’S USMLE</span>
            <span class="description">PREPARATION PROGRAM</span>
        </div>
        <div class="login-form">
            <div class="mt-5 ml-5 mr-5 mb-5" class="form">
                <label for="username">Enter Verification Code From your Email</label>
                <br>
                <input type="text" class="form-control" [(ngModel)]='code' name="code">
                <br>
                <div id="message" style="color: rgb(235, 83, 83); font-size: 14px; padding-bottom: 5px;" >{{message}}</div>
                <div class="login_buttons">
                    <button class="btn btn-success pl-5 pr-5 loginpass" style="border: none; margin-top: 10px;" (click)="authentication()">Login</button>
                </div>
            </div>
        </div>
        <div class="buttons">
            <a href="https://apps.apple.com/us/app/el-husseiny-usmle/id1544323137" style="text-decoration: none; color: white;">
                <button class="apple">
                    <img src="../../assets/images/apple.png">
                    <span style="margin-left: 10px;">Download On <span style="font-weight: 600; font-size: 22px;">App Store</span></span>
                </button>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.ni.ELHusseiny" style="text-decoration: none; color: white;">
                <button class="google">
                    <img src="../../assets/images/google.png">
                    <span style="margin-left: 10px;">Download On <span style="font-weight: 600; font-size: 24px;">Play Store</span></span>
                </button>
            </a>
        </div>
    </div>
    <div class="right_content">
        <img src="../../assets/images/login_mobile.png">
    </div>
</div>